import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";

const IndexPage = ({ data }) => {
  console.log(data);
  const posts = data.allMdx.edges;
  return (
    <Layout>
      <h2>Posts</h2>
      {posts.map((post) => (
        <div key={post.node.id} class="border-b-2 border-gray-50 py-4">
          <Link to={`/posts/${post.node.fields.slug}`}>
            <h3 class="text-2xl">{post.node.frontmatter.title}</h3>
          </Link>
          <p>{post.node.excerpt}</p>
        </div>
      ))}
    </Layout>
  );
};

export const pageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            slug
          }
          fields {
            slug
          }
          excerpt
          body
        }
      }
    }
  }
`;

export default IndexPage;
