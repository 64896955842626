import * as React from "react";
import { Link } from "gatsby";

const Layout = (props) => {
  const { children } = props;
  return (
    <div>
      <div class="relative bg-gray-900">
        <div class="max-w-7xl mx-auto px-4 sm:px-6">
          <div class="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div class="flex justify-start lg:w-0 lg:flex-1">
              <Link to="/">
                <span class="sr-only">Transfix</span>
                <img
                  class="h-8 w-auto"
                  src="https://transfix.io/wp-content/uploads/2022/02/trimmed.png"
                  alt=""
                />
              </Link>
            </div>
            <nav class="hidden md:flex space-x-10">
              <span class="text-white text-4xl tracking-wide">Under the Hood</span>
            </nav>
            <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <a
                href="https://transfix.io"
                class="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-200"
              >
                Transfix Homepage <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="max-w-2xl mx-auto px-4 pt-8">{children}</div>

      <footer className="text-gray-200 py-8 text-center">
        <div>© {new Date().getFullYear()} Transfix </div>
        {/* TODO: add more info about Transfix (product callouts, stack, hiring & links) */}
        {/* TODO: add a truck logo here */}
      </footer>
    </div>
  );
};

export default Layout;
